<template>
  <div v-loading.fullscreen.lock="loading">
    <div v-if="!downloadable" class="register">
      <div class="customer" @click="handleContactService">
        <img alt="" src="../assets/customer.png" />
      </div>
      <div class="logo">
        <img alt="" src="../assets/logo.png" />
      </div>
      <div class="main">
        <el-form
          ref="ruleForm"
          :model="registerForm"
          :rules="rules"
          class="demo-ruleForm"
          label-width="60px"
        >
          <el-form-item prop="phone">
            <span slot="label">
              <img alt="" src="../assets/phone.png" />
            </span>
            <el-input
              v-model="registerForm.phone"
              maxlength="9"
              minlength="8"
              type="phone"
              placeholder="Digite o número de telefone"
            >
              <template slot="prepend">+258</template>
            </el-input>
          </el-form-item>
          <!--屏蔽图形验证码-->
          <el-form-item prop="captcha_text">
            <span slot="label">
              <img alt="" src="../assets/getcode.png" />
            </span>
            <el-input
              v-model="registerForm.captcha_text"
              type="text"
              maxlength="5"
              minlength="5"
              placeholder="Insira o Captcha"
            ></el-input>
            <img
              class="captcha"
              :src="captcha.img"
              @click="fetchCaptcha"
              alt="CAPTCHA"
            />
          </el-form-item>
          <!--屏蔽短信验证码
          <el-form-item prop="otp">
            <span slot="label">
              <img alt="" src="../assets/getcode.png" />
            </span>
            <el-input
              v-model="registerForm.otp"
              maxlength="6"
              minlength="6"
              type="number"
              placeholder="Enter OTP code"
            ></el-input>
            <div
              v-if="countdownEnd === 0"
              class="sendcode"
              :class="{ disabled: !otpSendable }"
              @click="handleSendOTPCode"
              v-text="'Send OTP'"
            />
            <div
              v-else
              class="sendcode"
              :class="{ disabled: !otpSendable }"
              @click="handleSendOTPCode"
              v-text="countdownEnd - nowTime"
            />
          </el-form-item>
          -->
          <el-form-item prop="password">
            <span slot="label">
              <img alt="" src="../assets/password1.png" />
            </span>
            <el-input
              ref="password1"
              v-model="registerForm.password"
              maxlength="12"
              minlength="6"
              type="password"
              placeholder="Digite a senha de 6 a 12 dígitos"
            ></el-input>
            <img
              alt=""
              class="seeimg"
              src="../assets/password2.png"
              @click="handleShowPassword('password1')"
            />
          </el-form-item>
          <el-form-item prop="password1">
            <span slot="label">
              <img alt="" src="../assets/password1.png" />
            </span>
            <el-input
              ref="password2"
              v-model="registerForm.password1"
              maxlength="12"
              minlength="6"
              type="password"
              placeholder="Repita sua senha"
            ></el-input>
            <img
              alt=""
              class="seeimg"
              src="../assets/password2.png"
              @click="handleShowPassword('password2')"
            />
          </el-form-item>
          <el-form-item prop="invitation_code">
            <span slot="label">
              <img alt="" src="../assets/code.png" />
            </span>
            <template slot="prepend">+258

            </template>
            <el-input
              v-model="registerForm.invitation_code"
              type="text"
              placeholder="Nenhum código de convite"
              readonly="readonly"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="registerbtn">
        <div class="btn" @click="handleRegister('ruleForm')">Registro</div>
<!--        不显示download按钮-->
          <div class="downbox" @click="handleDownload">
            <img src="../assets/waaddme.png">
          </div>
      </div>
    </div>
    <div v-else class="down" @click="handleDownload">
      <img src="../assets/down.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
  data() {
    let password1change = (rule, value, callback) => {
      if (value !== this.registerForm.password) {
        callback(new Error("As duas saídas de senha são inconsistentes"));
      } else {
        callback();
      }
    };
    return {
       apiBaseUrl: "https://api.futurebitmining.com",
      downloadable: false,
      loading: true,
      registerForm: {
        phone: "",
        // otp: "",
        password: "",
        password1: "",
        invitation_code: "",
        name: "",
        email: "",
        captcha_text: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "Por favor insira seu número de telefone",
            trigger: "blur",
          },
          {
            min: 8,
            max: 9,
            message: "O comprimento é de 9 caracteres",
            trigger: "blur",
          },
        ],
        captcha_text: [
          {
            required: true,
            message: "Por favor insira o Captcha",
            trigger: "blur",
          },
          {
            min: 5,
            max: 5,
            message: "Por favor insira o Captcha",
            trigger: "blur",
          },
        ],

        // otp: [
        //   {
        //     required: true,
        //     message: "Please enter the 6-digits OTP code",
        //     trigger: "blur",
        //   },
        //   {
        //     min: 6,
        //     max: 6,
        //     message: "Please enter the 6-digits OTP code",
        //     trigger: "blur",
        //   },
        // ],
        password: [
          {
            required: true,
            message: "Por favor, insira sua senha",
            trigger: "blur",
          },
          {
            min: 6,
            max: 12,
            message: "Por favor, insira sua senha",
            trigger: "blur",
          },
        ],
        password1: [
          {
            required: true,
            message: "Por favor digite sua senha novamente",
            trigger: "blur",
          },
          {
            min: 6,
            max: 12,
            message: "Por favor digite sua senha novamente",
            trigger: "blur",
          },
          { validator: password1change, trigger: "blur" },
        ],
      },
      serviceContact: "",
      downloadUrl: "",
      countdownEnd: 0,
      nowTime: this.now(),
      captcha: {
        key: "",
        img: "",
      },
    };
  },
  computed: {
    otpSendable() {
      return (this.registerForm.phone.length === 8 || this.registerForm.phone.length === 9) && this.countdownEnd === 0 && this.registerForm.captcha_text.length === 5;
    },
  },
  watch: {
    "registerForm.phone"(phone) {
      const self = this;
      if (String(phone).length === 8 ||String(phone).length === 9) {
        const time = sessionStorage.getItem("_otp_258" + phone);
        self.countdownEnd = time && time > this.nowTime ? Number(time) : 0;
      } else {
        self.countdownEnd = 0;
      }
    },
  },
  created() {
    const query = this.$route.query;
    let invitation = query.invitation;
    if (invitation) {
      this.registerForm.invitation_code = invitation;
    }
    this.fetchLandingConfig();
    this.fetchCaptcha();
    this.countdown();
  },
  methods: {
    handleContactService() {
      if (this.serviceContact.length > 0) {
        window.location.href = this.serviceContact;
      }
    },
    handleShowPassword(id) {
      if (id === "password1") {
        this.$refs.password1.type = "text";
      } else {
        this.$refs.password2.type = "text";
      }
    },
    handleSendOTPCode() {
      if (!this.otpSendable) {
        return;
      }
    //   用户点击send sms ，就开始倒计时；
            this.countdownEnd = this.nowTime + 120;
            sessionStorage.setItem("_otp_" + phone, String(this.countdownEnd));

      let phone = this.registerForm.phone;
      let parsedata = {
        phone: phone,
        captcha_key: this.captcha.key,
        captcha_text: this.registerForm.captcha_text,
      };
      this.$http({
        headers: {
          "Content-Type": "application/json",
        },
        transformRequest: [
          function (data) {
            data = JSON.stringify(data);
            return data;
          },
        ],
        url: this.apiBaseUrl + "/otp",
        method: "post",
        data: parsedata,
      })
        .then((res) => {
          const data = res.data;
          if (data.status === 0) {
            // this.countdownEnd = this.nowTime + 120;
            // sessionStorage.setItem("_otp_" + phone, String(this.countdownEnd));
           //短信发送成功，就弹出提示；
            this.$notify.success({
              title: "OTP sent successfully, please check SMS.",
            });
          } else if(data.status === 500){
            this.$notify.error({
              title: "Error",
              message:"OTP send failed, please contact customer service.",

            });
          } else {
            this.registerForm.captcha_text = "";
            this.fetchCaptcha();
            this.$notify.error({
              title: "Error",
              message: data.message,
            });
          }
        })
        .catch((err) => {
          this.registerForm.captcha_text = "";
          this.fetchCaptcha();
          this.$notify.error({
            title: "Error",
            message: err.message,
          });
        });
    },
    fetchLandingConfig() {
      this.$http({
        headers: {
          "Content-Type": "application/json",
        },
        transformRequest: [
          function (data) {
            data = JSON.stringify(data);
            return data;
          },
        ],
        url: this.apiBaseUrl + "/landing",
        method: "get",
      })
        .then((res) => {
          const data = res.data;
          if (data.status === 0) {
            this.serviceContact = data.data.service_contact;
            this.downloadUrl = data.data.download_url;
            this.loading = false;
          } else {
            this.$notify.error({
              title: "Error",
              message: data.message,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            message: err.message,
          });
        });
    },
    fetchCaptcha() {
      this.$http({
        headers: {
          "Content-Type": "application/json",
        },
        transformRequest: [
          function (data) {
            data = JSON.stringify(data);
            return data;
          },
        ],
        url: this.apiBaseUrl + "/captcha-request",
        method: "get",
      })
        .then((res) => {
          const data = res.data;
          if (data.status === 0) {
            this.captcha.key = data.data.key;
            this.captcha.img = data.data.img;
            this.registerForm.captcha_text = "";
          } else {
            this.$notify.error({
              title: "Error",
              message: data.message,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            message: err.message,
          });
        });
    },
    handleDownload() {
      window.location.href = this.downloadUrl;
    },
    //注册不传国家码
    handleRegister(formName) {
      let formdata = {
        password: this.registerForm.password,
        //无需OTP，传图形验证码
        // otp: this.registerForm.otp,
        captcha_key: this.captcha.key,
        captcha_text: this.registerForm.captcha_text,
        //
        invitation_code: this.registerForm.invitation_code,
        name: this.registerForm.name,
        email: this.registerForm.email,
        phone: this.registerForm.phone,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            headers: {
              "Content-Type": "application/json",
            },
            transformRequest: [
              function (data) {
                data = JSON.stringify(data);
                return data;
              },
            ],
            url: this.apiBaseUrl + "/register",
            method: "post",
            data: formdata,
          })
            .then((res) => {
              const data = res.data;
              if (data.status === 0) {
                this.$notify.success({
                  title: "Registrado com sucesso",
                });
                this.downloadable = true;
                setTimeout(() => {
                  this.handleDownload();
                }, 100);
              } else if (data.status === 409) {
                this.$notify.success({
                  title: "O número de telefone já foi cadastrado",
                });
                this.downloadable = true;
                setTimeout(() => {
                  this.handleDownload();
                }, 100);
              } else if(data.status === 422){
                this.registerForm.captcha_text = "";
                this.fetchCaptcha();
                this.$notify.error({
                  title: "Error",
                  message: data.data.captcha_text,
                });
              }else {
                this.$notify.error({
                  title: "Error",
                  message: data.message,
                });
              }
            })
            .catch((err) => {
              this.$notify.error({
                title: "Error",
                message: err.message,
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    countdown() {
      const self = this;
      setTimeout(() => {
        self.nowTime = this.now();
        if (self.countdownEnd !== 0 && self.nowTime >= self.countdownEnd) {
          self.countdownEnd = 0;
        }
        self.countdown();
      }, 1000);
    },
    now() {
      return (Date.now() / 1000) | 0;
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  position: relative;
  height: 100vh;
}

.customer {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 50px;
  height: 50px;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.customer img {
  width: 50px;
}

.logo {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 80px;
}

//输入框范围的颜色
.main {
  padding: 20px 20px 20px 15px;
  background-color: #fff;
  /*background-color: #133a56;*/
  margin: 30px 10px;
  border-radius: 10px;
  background-size:auto;

  img {
    width: 30px;
    margin-top: 5px;
  }

  /deep/ .el-input__inner {
    background: #fff;
  }
}

.sendcode {
  width: 80px;
  position: absolute;
  font-size: 12px;
  color: #fff;
  background-color: #01a8fe;
  text-align: center;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  top: 5px;
  right: 5px;

  &.disabled {
    background-color: #999;
  }
}

.captcha {
  width: auto !important;
  position: absolute;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  top: 2px;
  right: 5px;
}

.see {
  width: 40px;
  margin: 5px 10px 5px 10px;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.seeimg {
  width: 30px;
  position: absolute;
  right: 5px;
  top: 10px;
}

.registerbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  position:relative;
  padding-top:10px;
}

.registerbtn .btn {
  height: 40px;
  width: 100px;
  line-height: 36px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  background-color: #46B878;
  color: #fff;
  padding: 0 10px;
  font-size:20px
}

.downbox{
    vertical-align: bottom;
    margin-top: -41px;
    position: absolute;
    right: 10px;
}
.downbox img{
  height:80px
}

.down {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
